// 福华基金
<template>
  <div class="partyCulture-fund">
    <Info @setData="setData" class="fund-info back-border" />
    <div class="fund-honor">
      <h4 class="honor-title">荣誉证书</h4>
      <InfoImg v-if="isData.length" :isData="isData" @setData="setData" :span="6" :gutter="20" field="logo" />
    </div>
  </div>
</template>

<script>
import Info from '@/components/Info'
import InfoImg from '@/components/InfoImg'
export default {

  components: {
    Info,
    InfoImg
  },
  data () {
    return {
      isData: []
    }
  },
  methods: {
    setData (data) {
      this.isData = data
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .fund-info {
  padding: 38px 53px 48px;
  margin-bottom: 45px;
  p {
    line-height: 30px;
    &:first-child {
      margin-bottom: 40px;
    }
  }
}
.honor-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 28px;
}
.infoImg-item {
  text-align: center;
  .el-image {
    width: 227px;
  }
}
</style>
