<template>
  <el-row class="infoImg-list" :gutter="gutter">
    <el-col :class="['infoImg-item',isActive && imgUrl==item?'infoImg-item-active':'']" :span="span"
      v-for="(item,index) in data" :key="index" @click.native="previewImg(item)">
      <el-image v-if="preview" :src="item" :preview-src-list="data"></el-image>
      <el-image v-else :src="item"></el-image>
      <div class="item-active-box" v-if="isActive && imgUrl==item"></div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo } from '@/api'
export default {
  name: 'InfoImg',
  props: {
    field: { // 要查询的字段名称
      type: String,
      default: 'text'
    },
    gutter: {
      type: Number,
      default: 0
    },
    span: {
      type: Number,
      default: 8
    },
    preview: { // 是否可以放大展示
      type: Boolean,
      default: false
    },
    isData: { // 数据是否已经存在
      type: Array,
      default: () => { return [] }
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      imgUrl: ''
    };
  },
  computed: {
    ...mapGetters(['getNavItem'])
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      if (this.getNavItem) {
        if (this.isData.length) {
          var data = this.isData
        } else {
          var { data } = await getInfo(this.getNavItem.id)
          this.setData(data)
        }
        let dataArr = JSON.parse(data[0][this.field])
        for (let i = 0; i < dataArr.length; i++) {
          this.data.push(this.$base + dataArr[i])
        }
        this.previewImg(this.data[0])
      }
    },
    setData (data) {
      this.$emit('setData', data)
    },
    previewImg (imgUrl) {
      this.imgUrl = imgUrl
      this.$emit('previewImg', imgUrl)
    }
  },
};
</script>

<style scoped lang="less">
.infoImg-item {
  margin-bottom: 30px;
  cursor: pointer;
}
</style>
